<template>
  <div class="editInstallationQuestionTemplate">
    <template v-if="loading">
      <LoadingPlaceholder />
    </template>
    <template v-else>
      <template v-if="template">
        <div class="row">
          <div class="col-12">
            <label class="font-weight-bold">{{ $t('installationType') }}</label>
            <select
              v-model="template.installationTypeId"
              :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('InstallationTypeId') }]"
            >
              <option
                v-for="(type, index) in types"
                :key="'option'+index"
                :value="type.id"
              >
                <span>{{ type.name }}</span>
              </option>
            </select>
            <span
              v-show="errors.has('InstallationTypeId')"
              class="badge badge-danger"
            >{{ errors.first('InstallationTypeId') }}</span>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <button
              :disabled="!readyToAdd"
              class="btn btn-primary float-right"
              @click="updateTemplate()"
            >
              <font-awesome-icon
                class="mr-1"
                icon="upload"
              />
              <span>
                {{ $t('save') }}
              </span>
            </button>
            <div class="clearfix" />
          </div>
        </div>
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js'

export default {
  name: 'EditInstallationQuestionTemplate',
  mixins: [
    errorMixin
  ],
  props: {
    templateId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      template: null,
      types: null
    }
  },
  computed: {
    readyToAdd: function () {
      if (this.template.installationTypeId && this.types) return true;
      return false;
    }
  },
  mounted () {
    this.getData();
  },
  methods: {
    getData () {
      this.loading = true;
      this.axios.all([
        this.axios.get('/Installation/GetInstallationTypes'),
        this.axios.get(`/Installation/GetQuestionTemplate?templateId=${ this.templateId }`)
      ]).then(
        this.axios.spread((typesResponse, templateResponse) => {
          this.types = typesResponse.data;
          this.template = templateResponse.data;
        }
      ))
      .finally(() => {
        this.loading = false;
      });
    },
    updateTemplate () {
      this.loading = true;
      let tmpTemplate = {
        "InstallationTypeId": this.template.installationTypeId,
        "Id": this.templateId,
      };
      this.axios.put('/Installation/EditInstallationQuestionTemplate', tmpTemplate)
        .then(() => {
          this.error_clear();
          this.$emit("reloadTemplates");
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>