<template>
  <div class="addInstallationQuestionTemplate">
    <template v-if="loading">
      <LoadingPlaceholder />
    </template>
    <template v-else>
      <div class="row">
        <div class="col-12">
          <label class="font-weight-bold">{{ $t('installationType') }}</label>
          <select
            v-model="installationTypeId"
            :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('InstallationTypeId') }]"
          >
            <option
              v-for="(type, index) in types"
              :key="'option'+index"
              :value="type.id"
            >
              <span>{{ type.name }}</span>
            </option>
          </select>
          <span
            v-show="errors.has('InstallationTypeId')"
            class="badge badge-danger"
          >{{ errors.first('InstallationTypeId') }}</span>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <button
            v-if="readyToAdd"
            class="btn btn-primary float-right"
            @click="addTemplate()"
          >
            <font-awesome-icon
              class="mr-1"
              icon="upload"
            />
            <span>
              {{ $t('save') }}
            </span>
          </button>
          <button
            v-else
            disabled
            class="btn btn-primary float-right"
          >
            <font-awesome-icon
              class="mr-1"
              icon="upload"
            />
            <span>
              {{ $t('save') }}
            </span>
          </button>
          <div class="clearfix" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js'

export default {
  name: 'AddInstallationQuestionTemplate',
  mixins: [
    errorMixin
  ],
  data () {
    return {
      loading: true,
      types: null
    }
  },
  computed: {
    readyToAdd: function () {
      if (this.installationTypeId && this.types) return true;
      return false;
    }
  },
  created () {
    this.getData();
  },
  methods: {
    getData () {
      this.loading = true;
      this.axios.get('/Installation/GetInstallationTypes')
        .then((response) => {
          this.types = response.data;
          this.installationTypeId = this.types[0].id;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addTemplate () {
      this.loading = true;
      let tmpTemplate = {
        "installationTypeId": this.installationTypeId
      };
      this.axios.post('/Installation/AddInstallationQuestionTemplate', tmpTemplate)
        .then(() => {
          this.error_clear();
          this.$emit("reloadTemplates");
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>