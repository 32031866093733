var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"installationQuestionTemplateList"},[(_vm.loading)?_c('LoadingPlaceholder'):[_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.templates),"columns":_vm.kgm_responsiveColumns(),"filterable":true,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.templates),"sortable":{
        allowUnsort: _vm.kgm_allowUnsort,
        mode: _vm.kgm_sortMode
      },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"idFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"idFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"Filter by Id"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('idFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"typeFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('select',{key:("typeSelect-" + (props.value)),ref:"typeSelect",staticClass:"form-control mr-2 rounded-right alt-pointer",domProps:{"value":props.value},on:{"change":function (event) {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('pleaseSelectOne'))+" ")]),_vm._l((_vm.installationTypes),function(type,key){return _c('option',{key:("deviceSearch-typeSelectOption-" + key),domProps:{"value":type,"selected":_vm.kgm_selectDefaultOption(props, type, 'typeSelect')}},[_vm._v(" "+_vm._s(type)+" ")])})],2),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetSelect('typeSelect', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"optionsTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.editTemplate(props.dataItem.id)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"edit"}}),_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])],1),_c('router-link',{staticClass:"btn btn-primary btn-sm",attrs:{"to":("/installation-questions/template/" + (props.dataItem.id))}},[_c('font-awesome-icon',{staticClass:"mr-2",staticStyle:{"vertical-align":"middle"},attrs:{"icon":"eye"}}),_c('span',[_vm._v("Questions")])],1),_c('button',{staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.openConfirmRemoveModal(props.dataItem.id)}}},[_c('font-awesome-icon',{staticClass:"mr-2 gray",attrs:{"icon":"trash"}}),_c('span',[_vm._v(_vm._s(_vm.$t('remove')))])],1)],1)])]}}])}),_c('Sidebar',{attrs:{"show-sidebar":_vm.showSidebar},on:{"close":_vm.hideSidebar}},[_c(_vm.currentComponent,{tag:"component",attrs:{"template-id":_vm.currentId},on:{"reloadTemplates":_vm.reloadTemplatesAndCloseSidebar}})],1)],_c('SweetModal',{ref:"confirmDelete",staticClass:"overflowHidden",attrs:{"icon":"warning","blocking":"","title":"Delete template?"}},[_c('p',[_vm._v("Are you sure you want to delete this template?")]),_c('button',{staticClass:"btn btn-secondary float-left mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.$refs.confirmDelete.close()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2 gray",attrs:{"icon":"times"}}),_c('span',[_vm._v(_vm._s(_vm.$t('cancel')))])],1),_c('button',{staticClass:"btn btn-danger float-right mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.removeTemplate()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"trash"}}),_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])],1),_c('div',{staticClass:"clearfix"})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }